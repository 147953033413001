import { Skeleton } from '@dovera/design-system';
import { cx } from '../../utils/exports';

interface Props {
  displayBlock?: boolean;
}

const TextareaPreloader = ({ displayBlock }: Props) => (
  <div>
    <Skeleton
      className={cx(displayBlock && 'd-block', 'mb-xxsmall')}
      height={16}
      width={150}
    />
    <Skeleton
      className={cx(displayBlock && 'd-block')}
      height={96}
      width="100%"
    />
  </div>
);

export default TextareaPreloader;
