import strings from '.';
import { EventType, PROPOSAL_TYPES } from '../../types/proposals.types';
import { inlineStrCondition } from '../../utils/app.utils';
import { formatDate } from '../../utils/date.utils';
import { firstCharToUpper } from '../../utils/strings.utils';

export default {
  drugs: {
    title: 'Návrh na schválenie úhrady lieku',
    new: {
      step3Title: 'Základné informácie o návrhu',
      inputErrors: {
        product: 'Začnite písať kód alebo názov produktu.',
        diagnose: 'Začnite písať kód alebo názov diagnózy.',
        epicrisisLength: (fieldName: string) =>
          `${firstCharToUpper(fieldName)} musí obsahovať aspoň 160 znakov.`,
        reasonLength: 'Zdôvodnenie liečby musí obsahovať aspoň 160 znakov.',
        incorrectValue:
          'Prepíšte, prosím, text. Po prekopírovaní a vložení obsahuje nepovolený zdrojový kód.',
      },
      labels: {
        dg: 'Diagnóza',
        contraindication: 'Má pacient niektorú z kontraindikácií?',
        permission:
          'Má pacient povolenie od ministerstva zdravotníctva na úhradu lieku?',
        indicativeLimit: 'Kód indikačného obmedzenia',
        productCode: 'Produkt / kód produktu',
        epicrisis: 'Epikríza',
        reason: 'Zdôvodnenie liečby',
        dateFrom: 'Dátum od',
        quantity: 'Množstvo / počet balení',
        dosage: 'Dávkovanie',
        dosageDescription: 'Popis spôsobu dávkovania',
      },
      recommendedProducts: {
        ntf: 'Pre tento produkt existujú aj výhodnejšie možnosti.',
        btn: 'Pozrieť odporúčané náhrady',
      },
      modals: {
        cancelAction: {
          title: 'Chcete zrušiť návrh?',
          description:
            'Rozpracovaný návrh nebude uložený a budete ho musieť vyplniť odznova.',
          btnContinue: 'Pokračovať',
          btnCancel: 'Zrušiť návrh',
        },
        recommended: {
          products: {
            title: 'Odporúčané náhrady',
            btn: 'Použiť náhradu',
          },
          indicatorLimits: {
            title: 'Výber indikačného kódu',
            btn: 'Použiť indikačný kód',
          },
        },
        notValid: {
          title:
            'Ľutujeme, návrh nespĺňa podmienky.<br />Chcete ho aj tak odoslať?',
          contraindications:
            'Ak má pacient niektorú z kontraindikácií, návrh môže byť zamietnutý.',
          description:
            'Návrh zrejme nebudeme môcť schváliť, keďže neboli<br /> splnené tieto podmienky:',
          patientIsDebtor:
            'Pacient je dlžník. Na schválenie úhrady produktu bude mať nárok <b>až po zaplatení dlhu.</b>',
          hasContraindication: 'Pacient <b>má niektorú z kontraindikácií.</b>',
          treatmentNotFollowedChronicPatient:
            '<b>Nebola dodržaná liečba</b> chronického pacienta.',
          ageCriteriaForProductNotFulfilled:
            'Pacient <b>nespĺňa vekové kritériá</b> pre tento produkt.',
          forWomen: 'Tento produkt je <b>určený pre ženy.</b>',
          forMen: 'Tento produkt je <b>určený pre mužov.</b>',
          productNoLongerValid: 'Tento <b>produkt už nie je platný.</b>',
          incorrectDate: 'Vyberte dátum z kalendára. Nesmie byť v minulosti.',
          expertise:
            'Pri Vašej odbornosti nemôžete podať návrh na tento produkt.',
          buttons: {
            submit: 'Odoslať návrh',
            cancel: 'Zrušiť návrh',
            edit: 'Upraviť návrh',
          },
        },
      },
      notifications: {
        error: {
          notInsuree: 'Nie je možné evidovať návrh pre neaktívneho poistenca.',
          epicrisisReason:
            'Napíšte epikrízu a zdôvodnenie liečby. Ak máte obe uvedené v prílohe, stačí ju nahrať.',
          indications:
            'Ak má pacient niektorú z indikácií, návrh môže byť zamietnutý.',
          contraindications:
            'Ak má pacient niektorú z kontraindikácií, návrh môže byť zamietnutý.',
          productDoesNotExist:
            'Skontrolujte, či ste zadali správny názov alebo kód produktu.',
          diagnoseDoesNotExist:
            'Skontrolujte, či ste zadali správny názov alebo kód diagnózy.',
          productNoLongerValid:
            'Tento produkt už nie je platný. Prosím, vyberte iný.',
          startWritingProduct: 'Začnite písať kód alebo názov produktu',
          startWritingDiagnose: 'Začnite písať diagnózu',
          patientIsDebtor: `<b>Pacient je dlžník.</b> Na schválenie úhrady produktu bude mať nárok až po zaplatení dlhu.`,
          productNotReimbursed: 'Tento produkt neuhrádzame.',
          doesNotHaveSectionCnp:
            'K tomuto produktu nemáte prístup. Prosím, kontaktujte objednavka_cnp@dovera.sk',
          doesNotHaveSection:
            'K tomuto produktu nemáte prístup. Prosím, kontaktujte olp@dovera.sk',
          notValidDate: 'Vyberte dátum z kalendára. Nesmie byť v minulosti.',
          isProposalRequired: 'Pre tento produkt návrh nie je potrebný.',
          expertise:
            'Pri Vašej odbornosti nemôžete podať návrh na tento produkt.',
          insureeAge:
            'Poistenec nespĺňa vekové kritériá pre tento produkt.<br />Ak návrh podáte, môže byť zamietnutý.',
          onlyForWomen:
            'Tento produkt je určený pre ženy. Mužom ho neuhrádzame.',
          onlyForMen:
            'Tento produkt je určený pre mužov. Ženám ho neuhrádzame.',
          existsDuplicity: 'Návrh na úhradu tohto produktu bol už podaný.',
          tooEarly: (date: string) =>
            `Návrh na úhradu tohto produktu bol už podaný. <b>Ďalší môžete opäť podať ${formatDate(date)}.</b>`,
        },
      },
      options: {
        has: 'Má',
        hasnt: 'Nemá',
      },
      buttons: {
        cancelProposal: 'Zrušiť návrh',
        continue: 'Pokračovať',
        indicatorList: 'Zobraziť indikačné kódy',
      },
      result: {
        success: {
          cnp: {
            title: (proposalNumber?: string | null) =>
              `Návrh${proposalNumber ? ` č. ${proposalNumber}` : ''} sme prijali. O schválení Vás budeme<br />informovať do 8 pracovných dní.`,
            description:
              'Tento liek je centrálne nakupovaný. Po schválení návrhu liek dodáme do určenej lekárne.<br /><br />Výsledok schvaľovania pošleme mailom Vám aj pacientovi.',
            primaryBtn: {
              text: 'Späť na domov',
              route: '/',
            },
            secondaryBtn: 'Vytvoriť nový návrh',
          },
          drug: {
            title: (proposalNumber?: string | null) =>
              `Návrh${proposalNumber ? ` č. ${proposalNumber}` : ''} sme prijali. O schválení Vás budeme<br />informovať do 8 pracovných dní.`,
            description: 'Výsledok nájdete v časti Návrhy.',
            primaryBtn: {
              text: 'Späť na domov',
              route: '/',
            },
            secondaryBtn: 'Vytvoriť nový návrh',
          },
        },
        error: {
          title: 'Návrh pravdepodobne nebude schválený',
          description:
            'Prijali sme návrh, ktorý nespľňa podmienky schválenia.<br />Výsledok potvrdíme Vám aj pacientovi do 12 hodín.',
          primaryBtn: {
            text: 'Domov',
            route: '/',
          },
          secondaryBtn: 'Vytvoriť nový návrh',
        },
        warning: {
          title: 'Prepáčte, návrh sa nepodarilo odoslať',
          description:
            'Skúste ho, prosím, vyplniť znovu.<br />Ak bude problém pretrvávať, <a href="mailto:poskytovatel@dovera.sk">napíšte nám</a>.',
          primaryBtn: {
            text: 'Vyplniť znovu',
          },
        },
      },
    },
  },
  spa: {
    modals: {
      cancelProposal: {
        title: 'Chcete zrušiť kúpeľný návrh?',
        description:
          'Rozpracovaný návrh <b>nebude uložený</b> a budete ho musieť vyplniť odznova.',
        buttons: {
          continue: 'Pokračovať vo vypĺňaní',
          cancel: 'Zrušiť návrh',
        },
      },
      reservationCode: {
        title: 'Zadajte rezervačný kód',
        label: 'Rezervačný kód',
        error: 'Tento kód nie je platný.',
        button: 'Pridať návrh',
      },
      reservationDate: {
        title: 'Zadajte dátumy rezervácie',
        titleUpdate: 'Zadajte nové dátumy rezervácie',
        titleDelete: 'Chcete zrušiť rezerváciu?',
        labels: {
          end: 'Koniec',
          start: 'Začiatok',
        },
        button: 'Potvrdiť',
        buttonUpdate: 'Upraviť',
        buttonDelete: 'Zrušiť',
      },
      toasts: {
        reservationDate: 'Dátum rezervácie bol pridaný.',
        reservationDateUpdated: 'Dátum bol upravený.',
        reservationDateDeleted: 'Rezervácia bola zrušená.',
      },
      notValid: {
        title: 'Návrh nespĺňa podmienky.<br />Chcete ho aj tak odoslať?',
        description:
          'Pravdepodobne ho nebudeme môcť schváliť, pretože<br />nie sú splnené tieto podmienky:',
        buttons: {
          submit: 'Odoslať návrh',
          cancel: 'Zrušiť návrh',
          edit: 'Upraviť návrh',
        },
        conditions: {
          debtor:
            'Pacient je dlžník. Na kúpele bude mať nárok <b>až po zaplatení dlhu</b>.',
          recommendations70:
            'Pacient nad 70 rokov potrebuje <b>odporúčania od internistu/kardiológa</b>.',
          contraindications: 'Pacient <b>má niektorú z kontraindikácií</b>.',
          noExaminations: 'Pacient <b>nemá potrebné vyšetrenia</b>.',
          svlz: '<b>Klinické a SVLZ vyšetrenia</b> musia mať menej ako 3 mesiace.',
          txsControls: {
            CerpanieKNsJednoraz:
              'Indikačná skupina povoľuje <b>jednorázové čerpanie kúpeľnej liečby.</b> Pacient ju už absolvoval.',
            CerpanieKNsPeriod: (term: number) =>
              `S danou indikačnou skupinou má pacient nárok na kúpele <b>${
                term === 24 ? 'raz za 2 roky' : 'raz za rok'
              }</b>.`,
            ExistSchvaleneRozhodnutie: 'Evidujeme schválené rozhodnutie.',
            NedostPocetNavstChronik:
              '<b>Nebola dodržaná liečba</b> chronického pacienta.',
            NedostPocetNavstSusLiecbu:
              'Neevidujeme pravidelnú liečbu diagnózy.',
            NenajdenaHospitalizacia: 'Nebola nájdená hospitalizácia pacienta.',
            NenajdenaOperacia: 'Nebola nájdená operácia pacienta.',
            EsteJePriskoro: 'Návrh podávate priskoro.',
            UzJeNeskoro: 'Návrh podávate neskoro.',
            IsVekPoi: 'Pacient nespĺňa vekové kritérium indikačnej skupiny.',
            Dlznik:
              'Pacient je dlžník. Na kúpele bude mať nárok <b>až po zaplatení dlhu</b>.',
          },
        },
      },
    },
    new: {
      title: 'Nový návrh',
      stepTitles: {
        step1: 'Navrhujúci lekár',
        step2: 'Informácie o pacientovi',
        step3: 'Diagnóza a indikačná skupina',
        step4: 'Objektívny nález a vedľajšie diagnózy',
        step5: 'Doplňujúce informácie',
      },
      modals: {
        cancelProposal: {
          title: 'Chcete zrušiť kúpeľný návrh?',
          description:
            'Rozpracovaný návrh <b>nebude uložený</b> a budete ho musieť vyplniť odznova.',
        },
        proposalWithErrors: {
          title: 'Návrh nespĺňa podmienky.<br />Chcete ho aj tak odoslať?',
          description:
            'Pravdepodobne ho nebudeme môcť schváliť, pretože<br />nie sú splnené tieto podmienky:',
        },
      },
      buttons: {
        continue: 'Pokračovať',
        cancel: 'Zrušiť návrh',
        showIndicatorsList: 'Pozrieť indikačný zoznam',
        submit: 'Odoslať návrh',
        savePDF: 'Uložiť ako PDF',
        continueFilling: 'Pokračovať vo vypĺňaní',
        edit: 'Upraviť návrh',
        newProposal: 'Vytvoriť nový návrh',
        home: 'Domov',
        tryAgain: 'Vyplniť znovu',
      },
      labels: {
        chooseDoctor: 'Vyberte lekára',
        chooseAmbulance: 'Vyberte ambulanciu',
        doctor: 'Lekár',
        ambulance: 'Ambulancia',
        email: 'E-mail',
        patientInsureeId: 'Rodné číslo pacienta',
        insureeId: 'Rodné číslo',
        patient: 'Pacient',
        name: 'Meno a priezvisko',
        address: 'Adresa',
        mobile: 'Mobil',
        indicatorGroup: 'Zadajte indikačnú skupinu',
        diagnose: 'Zadajte diagnózu',
        diagnoseDate: (type: 'L' | 'D' | 'H' | 'O'): string => {
          if (type === 'L') return 'Kedy bola ukončená liečba?';
          if (type === 'D') return 'Kedy bolo diagnostikované ochorenie?';
          return type === 'H'
            ? 'Kedy bola ukončená hospitalizácia?'
            : 'Kedy bol pacient na operácii?';
        },
        contraindications: 'Má pacient niektorú z kontraindikácií?',
        examinations: 'Má pacient tieto vyšetrenia?',
        SVLZ: 'Majú klinické a SVLZ vyšetrenia menej ako 3 mesiace?',
        reason: 'Popíšte dôvod kúpeľnej starostlivosti a epikrízu',
        medicalHistory: `Popíšte anamnézu, užívané lieky, patologický a objektívny nález so zhodnotením funkčného stavu`,
        secondaryDiagnoses: 'Vyberte vedľajšie diagnózy pacienta',
        bathType: 'Aký typ kúpeľnej staroslivosti si pacient praje?',
        bathTypeSingle: 'Kúpeľná starostlivosť',
        escort: 'Bude mať pacient sprievod?',
        helpingEscort: 'Vyberte, s čím bude sprievod pomáhať',
        mobility: 'Aká je pohyblivosť pacienta?',
        proposalConfirmation:
          'Má pacient <b>potvrdenie od internistu/kardiológa/geriatra, že zvládne kúpeľnú liečbu</b> (balneologickú záťaž?)',
      },
      summary: {
        indicatorGroup: 'Indikačná skupina',
        diagnose: 'Diagnóza',
        doctorConfirmation: 'Potvrdenie od internistu/kardiológa/geriatra',
        patientOperation: 'Pacient bol operovaný',
        patientHosp: 'Pacient bol hospitalizovaný',
        contraindications: 'Kontraindikácie',
        examinations: 'Vyšetrenia',
        svlz: 'Klinické a SVLZ vyšetrenia nie staršie ako 3 mesiace',
        epicrisis: 'Epikríza',
        medicalHistory: 'Anamnéza, patologický a objektívny nález',
        anotherDiagnoses: 'Vedľajšie diagnózy',
      },
      options: {
        yes: 'Áno',
        no: 'Nie',
        has: 'Má',
        hasnt: 'Nemá',
        spaType: (single?: boolean) => ({
          A: single ? 'Ambulantná' : 'Ambulantnú',
          U: single ? 'Ústavná' : 'Ústavnú',
        }),
        mobilityType: {
          C: 'Chodí',
          B: 'Chodí pomocou barlí',
          V: 'Je na vozíku',
        },
        walks: 'Chodí',
        crutches: 'Chodí pomocou barlí',
        weelchair: 'Je na vozíku',
        catering: 'Stravovanie',
        dressing: 'Obliekanie',
        hygiene: 'Osobná hygiena',
        wc: 'Presun na WC',
      },
      helpers: {
        insureeId: 'Zadajte bez lomky',
        contraindications:
          '<li>Infekčné choroby prenosné na človeka a bacilonosičstvo.<br />Ak je indikovaná choroba združená s tbc dýchacieho ústrojenstva alebo inou formou tbc, kúpeľná starostlivosť sa povoľuje, iba ak bol pacient vyradený z dispenzárnych skupín aktívnej tuberkulózy.</li><li>Všetky choroby v akútnom štádiu)</li>',
        secondaryDiagnoses: 'Povolených je maximálne 5 diagnóz.',
      },
      inputErrors: {
        diagnose: 'Začnite písať kód alebo názov diagnózy.',
        epicrisis: 'Odôvodnite, prosím, kúpeľnú starostlivosť.',
        epicrisisLength: 'Epikríza musí obsahovať aspoň 160 znakov.',
        indicatorGroup: 'Začnite písať kód alebo názov indikácie.',
        reason: 'Odôvodnite, prosím, kúpeľnú starostlivosť.',
        anamnese: 'Prosím, zadajte objektívny nález.',
        anamneseLength: 'Objektívny nález musí obsahovať aspoň 300 znakov.',
        incorrectValue:
          'Prepíšte, prosím, text. Po prekopírovaní a vložení obsahuje nepovolený zdrojový kód.',
      },
      notifications: {
        errors: {
          debtor:
            'Poistenec je dlžník. Na kúpele bude mať nárok <b>po zaplatení dlhu.</b>',
          inactiveInsuree:
            'Nie je možné evidovať kúpeľný návrh pre neaktívneho poistenca.',
          missingConfirmations:
            'Pacient bude mať na kúpele <b>nárok po doplnení potvrdení.</b>',
          hasContraindications:
            'Ak má pacient niektorú z kontraindikácií, <b>návrh pravdepodobne nebude schválený.</b>',
          missingExaminations:
            'Pacient bude mať na kúpele <b>nárok po doplnení vyšetrení.</b>',
          missingYoungerExaminations:
            'Pacient bude mať na kúpele <b>nárok po doplnení vyšetrení</b>, ktoré nie sú staršie ako 3 mesiace.',
          txsControls: {
            CerpanieKNsJednoraz:
              'Indikačná skupina povoľuje <b>jednorázové čerpanie kúpeľnej liečby.</b> Pacient ju už absolvoval.',
            CerpanieKNsPeriod: (term: number) =>
              strings.proposals.spa.modals.notValid.conditions.txsControls.CerpanieKNsPeriod(
                term,
              ),
            ExistSchvaleneRozhodnutie: 'Evidujeme schválené rozhodnutie.',
            NedostPocetNavstChronik:
              'Nebola dodržaná <b>liečba chronického pacienta.</b> Návrh pravdepodobne nebude schválený.',
            NedostPocetNavstSusLiecbu:
              'Neevidujeme pravidelnú liečbu diagnózy. Návrh pravdepodobne nebude schválený.',
            NenajdenaHospitalizacia: 'Nebola nájdená hospitalizácia pacienta.',
            NenajdenaOperacia: 'Nebola nájdená operácia pacienta.',
            EsteJePriskoro: 'Návrh podávate priskoro.',
            UzJeNeskoro: 'Návrh podávate neskoro.',
            IsVekPoi:
              'Pacient nespĺňa vekové kritérium indikačnej skupiny. Vyberte, prosím, inú skupinu.',
            Dlznik:
              'Pacient je dlžník. Na kúpele bude mať nárok <b>až po zaplatení dlhu</b>.',
          },
        },
        info: {
          copyOfExaminations:
            'Pacientovi poskytnite kópie vyšetrení. Bude ich potrebovať pri nástupe do kúpeľov.',
        },
      },
      tooltips: {
        epicrisis: 'Stručný záver prepúšťacej správy a odporúčanie.',
        moreThan70: 'Platí pre pacientov od 70 rokov vrátane.',
      },
      mustKnowBeforeSubmit: [
        'Kúpele si po novom vyberá pacient. Po schválení návrhu mu pošleme zoznam odporúčaných kúpeľov.',
        'Za správnosť obsahu návrhu zodpovedáte Vy ako navrhujúci lekár.',
        'Pacientovi poskytnite, prosím, zdravotnú dokumentáciu, ktorú kúpele požadujú. Poisťovňa ju neposkytuje.',
      ],
      afterSubmit: {
        success: {
          title: 'Návrh sme prijali, výsledok Vám potvrdíme do 24 hodín',
          description: 'Výsledok schvaľovania pošleme Vám aj pacientovi.',
        },
        error: {
          title: 'Návrh pravdepodobne nebude schválený',
          description:
            'Prijali sme návrh, ktorý nespľňa podmienky schválenia.<br />Výsledok potvrdíme Vám aj pacientovi do 24 hodín.',
        },
        warning: {
          title: 'Prepáčte, návrh sa nepodarilo odoslať',
          description:
            'Skúste ho, prosím, vyplniť znovu.<br />Ak bude problém pretrvávať, <a href="mailto:poskytovatel@dovera.sk">napíšte nám</a>.',
        },
      },
      result: {
        success: {
          title: (proposalNumber?: string | null): string => {
            const propNumber = inlineStrCondition(
              !!proposalNumber,
              `${proposalNumber} `,
              '',
            );
            return `Návrh ${propNumber}sme prijali. O tom, či bol schválený,<br />Vás budeme informovať do 24 hodín.`;
          },
          description:
            'Výsledok schvaľovania pošleme mailom Vám aj pacientovi.',
          secondaryBtn: 'Vytvoriť nový návrh',
          btn: 'Domov',
        },
        warning: {
          title: (proposalNumber?: string | null) => {
            const propNumber = inlineStrCondition(
              !!proposalNumber,
              `${proposalNumber} `,
              '',
            );
            return `Návrh ${propNumber}pravdepodobne nebude schválený`;
          },
          description:
            'Prijali sme návrh, ktorý nespľňa podmienky schválenia.<br />Výsledok potvrdíme Vám aj pacientovi do 24 hodín.',
          secondaryBtn: 'Vytvoriť nový návrh',
          btn: 'Domov',
        },
        error: {
          title: (proposalNumber?: string | null) => {
            const propNumber = inlineStrCondition(
              !!proposalNumber,
              `${proposalNumber} `,
              '',
            );
            return `Prepáčte, návrh  ${propNumber}sa nepodarilo odoslať`;
          },
          description:
            'Skúste ho, prosím, vyplniť znovu.<br />Ak bude problém pretrvávať, <a href="mailto:poskytovatel@dovera.sk">napíšte nám</a>.',
          btn: 'Vyplniť znovu',
        },
      },
    },
    list: {
      emptyState: 'Nenašli sme žiadne návrhy',
      approvedProposals: {
        title: 'Zoznam schválených návrhov',
        infoReservation:
          'Zadaním rezervačného kódu si pridáte schválený návrh do tabuľky.',
        labels: {
          reservationCode: 'Zadať rezervačný kód',
          date: 'Zadať dátumy',
          detail: 'Detail',
          proposal: 'Návrh',
          insuree: 'Pacient',
          doctorCode: 'Kód/meno lekára',
          validity: 'Platnosť návrhu',
          reservation: 'Rezervácia kúpeľov',
        },
        filter: {
          insuree: 'Pacient/Rodné číslo',
          reservation: 'Rezervačný kód',
          validityFrom: 'Platnosť návrhu od',
          validityTo: 'Platnosť návrhu do',
          proposalNumber: 'Číslo návrhu',
          spaTreatment: 'Kúpeľná liečba',
          insureeVZ: 'Poistný vzťah pacienta',
          more: 'Viac filtrov',
          less: 'Menej filtrov',
        },
        tooltips: {
          inactive: 'Pacient už nie je poistenec Dôvery.',
        },
      },
      submittedProposals: {
        title: 'Zoznam podaných návrhov',
        labels: {
          newProposal: 'Podať nový návrh',
          proposalNumber: 'Číslo návrhu',
          insuree: 'Pacient/Rodné číslo',
          state: 'Stav',
          doctorCode: 'Kód/meno lekára',
          ambulance: 'Ambulancia',
          validityFrom: 'Platnosť návrhu od',
          validityTo: 'Platnosť návrhu do',
        },
        filter: {
          proposalNumber: 'Číslo návrhu',
          insuree: 'Pacient/Rodné číslo',
          state: 'Stav návrhu',
          more: 'Viac filtrov',
          less: 'Menej filtrov',
          doctorCode: 'Kód/meno lekára',
          ambulance: 'Ambulancia',
          validityFrom: 'Platnosť návrhu od',
          validityTo: 'Platnosť návrhu do',
        },
      },
    },
    detail: {
      buttons: {
        create: 'Zadať dátum rezervácie',
        edit: 'Upraviť',
        delete: 'Zrušiť',
        printPdf: 'Uložiť ako PDF',
      },
      title: (proposalNumber: string) => `Návrh č. ${proposalNumber}`,
      subtitles: {
        patient: 'Pacient',
        proposalDoctor: 'Navrhujúci lekár',
        information: 'Informácie o návrhu o zdravotnú starostlivosť',
        additionalInfo: 'Doplňujúce informácie',
        documents: 'Dokumenty',
      },
      labels: {
        name: 'Meno a priezvisko',
        insureeId: 'Rodné číslo',
        address: 'Adresa',
        mobile: 'Mobil',
        doctor: 'Lekár',
        hospital: 'Nemocnica',
        ambulance: 'Ambulancia',
        email: 'E-mail',
        diagnose: 'Diagnóza',
        indicatorGroup: 'Indikačná skupina',
        aproove: 'Potvrdenie od internistu/kardiológa/geriatra',
        event: (str: EventType | string): string => {
          if (['Hospitalizacia', 'Operacia'].includes(str))
            return `Pacient bol ${inlineStrCondition(
              str === 'Hospitalizacia',
              'hospitalizovaný',
              'operovaný',
            )}`;
          if (str === 'Diagnostika') return 'Diagnostikované ochorenie';
          return str === 'UkoncenieLiecby' ? 'Ukončenie liečby' : '';
        },
        contraindications: 'Kontraindikácie',
        examinations: 'Vyšetrenia',
        svlz3Month: 'Klinické a SVLZ vyšetrenia nie staršie ako 3 mesiace',
        epicrisis: 'Epikríza',
        anannese: 'Anamnéza, patologický nález, objektívny nález',
        additionalDiagnoses: 'Vedľajšie diagnózy',
        spaType: 'Kúpeľná starostlivosť',
        escort: 'Sprievod',
        escortType: 'Dôvod sprievodu',
        mobility: 'Pohyblivosť pacienta',
        exposed: (str: string) => `Vystavený ${str}`,
        accepted: 'Prijatý poisťovňou',
        approved: 'Schválený',
        resultDate: 'Dátum spracovania',
        notApproved: 'Neschválený',
        validity: 'Platnosť návrhu',
        insuranceStatemenet: 'Vyjadrenie poisťovne',
        treatment: 'Kúpeľná liečba',
        pin: 'PIN kód',
        reservation: 'Rezervácia kúpeľov',
      },
    },
  },
  types: {
    [`${PROPOSAL_TYPES.SPA}`]: 'Kúpeľný návrh',
    [`${PROPOSAL_TYPES.Drugs}`]: 'Liekový návrh a CNP',
  },
  common: {
    new: {
      breadcrumbs: {
        proposals: 'Návrhy',
        listOfProposals: 'Zoznam podaných návrhov',
      },
      title: 'Nový návrh',
    },
    buttons: {
      cancel: 'Zrušiť',
      continue: 'Pokračovať',
      send: 'Odoslať',
    },
  },
};
