import React, { useEffect, useState } from 'react';
import useStyles from '../../../../Proposals.styles';
import strings from '../../../../../../constants/strings';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../rootReducer';
import { useAppDispatch } from '../../../../../../hooks/useStore';
import { checkProposalData } from '../../../../../../api/drugProposals';
import { CheckType } from '../../../../../../types/proposals.types';
import {
  DATE_FROMAT_SK_DATEPICKER,
  DATE_INTERNATIONAL,
  getMoment,
} from '../../../../../../utils/date.utils';
import moment from 'moment';
import { Notification } from '@dovera/design-system';
import { storeStep3 } from '../../../../../../slices/drugProposals.slice';
import DDSDatepicker from '../../../../../../components/DDSDatepicker/DDSDatepicker';
import CheckNotifications from '../../../common/CheckNotifications/CheckNotifications';
import { FieldElementIdEnum } from '../../../../../../types/drugProposals.types';
import { scrollToField } from '../../../../../../utils/form.utils';

const texts = strings.proposals.drugs.new;

interface DateFromProps {
  field: any;
}

const checkTypes: CheckType[] = [
  'DatumPlatnostiOd',
  'ExistDuplicita',
  'EsteJePriskoro',
];

const DateFrom = ({ field }: DateFromProps) => {
  const [minDate, setMinDate] = useState<Date | undefined>(undefined);
  const [error, setError] = useState('');
  const classes = useStyles({ proposalType: 'LN' });
  const [initDatepicker, setInitDatepicker] = useState(false);
  const [date, setDate] = useState('');
  const dispatch = useAppDispatch();
  const {
    drugProposals: {
      new: {
        data: { controls },
        stepper: {
          step3: { hasMV },
        },
      },
    },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (date?.length === 10 && moment(date).isValid()) {
      setError('');
      dispatch(
        checkProposalData({
          checkTypes,
          dateValidityFrom: getMoment(date).format(DATE_INTERNATIONAL),
        }),
      );
      dispatch(storeStep3({ dateFrom: date }));
    } else {
      setError(texts.notifications.error.notValidDate);
      dispatch(storeStep3({ dateFrom: '' }));
    }
  }, [date, dispatch]);

  useEffect(() => {
    setInitDatepicker(true);
    setTimeout(() => {
      setInitDatepicker(false);
    }, 500);
  }, [hasMV]);

  useEffect(() => {
    const initialDate =
      controls?.data?.find((c) => c.checkType === 'TypLiecby')?.additionalInfo
        ?.value4 || '';
    setTimeout(() => {
      field.input.onChange(
        moment(initialDate).format(DATE_FROMAT_SK_DATEPICKER),
      );
      setMinDate(new Date(initialDate));
      setDate(moment(initialDate).format(DATE_INTERNATIONAL));
    }, 500);
    // eslint-disable-next-line
  }, [dispatch, initDatepicker]);

  const renderNotification = error && !initDatepicker && (
    <div className="fit-content">
      <Notification message={error} variant="error" />
    </div>
  );
  if (!minDate) return null;
  return (
    <div className={classes.drugsWrapper}>
      <div className={classes.dateWrapper}>
        <DDSDatepicker
          {...field.input}
          autoComplete="off"
          error={field.meta.touched && field.meta.error}
          id={FieldElementIdEnum.DateFrom}
          isDisabled={
            controls.isLoadingControl?.includes('DatumPlatnostiOd') ||
            initDatepicker
          }
          isRequired
          label={texts.labels.dateFrom}
          minDate={minDate}
          onClick={() => scrollToField(FieldElementIdEnum.DateFrom)}
          // eslint-disable-next-line
          onChange={(value) => {
            field.input.onChange(
              moment(value).format(DATE_FROMAT_SK_DATEPICKER),
            );
            setTimeout(
              () => setDate(moment(value).format(DATE_INTERNATIONAL)),
              100,
            );
            scrollToField(FieldElementIdEnum.DateFrom);
          }}
          pikadayOptions={{
            format: DATE_FROMAT_SK_DATEPICKER,
          }}
        />
      </div>
      {renderNotification}
      <CheckNotifications checkTypes={checkTypes} />
    </div>
  );
};

export default DateFrom;
