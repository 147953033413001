import { saveProposal } from '../api/drugProposals';
import store from '../store';
import {
  DrugProposalField,
  DrugProposalSteps,
  FieldElementId,
  FieldElementIdEnum,
  SaveProposalPayload,
} from '../types/drugProposals.types';
import { checkWithNoMessage } from '../constants/drugProposals';
import strings from '../constants/strings';
import { CheckType, ProposalCheck, Result } from '../types/proposals.types';
import moment from 'moment';
import { Dokument } from '../types/models/Dokument';
import { LoadingState } from '../types';
import { scrollToField } from './form.utils';

export const canShowField = (field: DrugProposalField): boolean => {
  const globalState = store.getState()?.drugProposals?.new;
  if (!globalState) return false;
  const {
    data: {
      controls,
      formData: { data, dataState },
    },
    stepper: { step3 },
  } = globalState;
  const dgControl =
    controls.data.some(
      (c) => c.checkType === 'MaSekciuPreProdukt' && c.result === 'OK',
    ) &&
    controls.data.some(
      (c) => c.checkType === 'EsteJePriskoro' && c.result === 'OK',
    );
  const commonStep3Control =
    dgControl &&
    ((!data?.udajIndikacnehoObmedzenia?.length && step3.hasMV !== null) ||
      (!controls.data.some((c) => c.checkType === 'MaMV') &&
        !!step3.indicatorLimitation?.code) ||
      controls.data.some((c) => c.checkType === 'MaMV' && c.result === 'OK'));
  const isDateValid =
    step3.dateFrom?.length === 10 && moment(step3.dateFrom).isValid();
  const showContraindication: boolean =
    !data?.udajParLiekNavrhuCnp?.kontraIndikacie?.length ||
    !!step3.hasContraindication;
  switch (field) {
    case DrugProposalField.Diagnose:
      return dgControl;
    case DrugProposalField.MinisterException:
      return (
        !!step3.product?.code &&
        !!step3.diagnose?.kod &&
        controls.data.some(
          (c) => c.checkType === 'MaSekciuPreProdukt' && c.result === 'OK',
        )
      );
    case DrugProposalField.IndicatorLimitationCode:
      return step3.hasMV !== null && !!data?.udajIndikacnehoObmedzenia?.length;
    case DrugProposalField.Contraindication:
      return commonStep3Control && dataState === LoadingState.filled;
    case DrugProposalField.DateFrom:
      return (
        commonStep3Control &&
        showContraindication &&
        dataState === LoadingState.filled
      );
    case DrugProposalField.QuantityDosage:
      return (
        !!step3.dateFrom &&
        controls.data.some((c) => c.checkType === 'DatumPlatnostiOd') &&
        commonStep3Control &&
        isDateValid &&
        showContraindication &&
        dataState === LoadingState.filled
      );
    case DrugProposalField.Reason:
      return (
        !!step3.quantity &&
        commonStep3Control &&
        isDateValid &&
        showContraindication &&
        dataState === LoadingState.filled &&
        (!data?.udajParLiekNavrhuCnp?.mnozstvo ||
          Number(step3.quantity) <=
            Number(data?.udajParLiekNavrhuCnp?.mnozstvo))
      );
    default:
      return false;
  }
};

export const saveDrugProposal = (files: Dokument[] | null) => {
  const {
    data: {
      formData: { data, formId },
    },
    stepper: { step1, step2, step3 },
  } = store.getState().drugProposals.new;
  const payload: SaveProposalPayload = {
    datumPlatnostiOd: step3.dateFrom,
    davkovanie: step3.dosage || '',
    epikriza: step3.epicrisis || '',
    formularId: formId,
    jednotkaMnozstva: data?.udajParLiekNavrhuCnp?.jednotkaMnozstva || null,
    kodATCSkupiny: step3.product?.atc || null,
    kodDiagnoza: step3.diagnose?.kod || '',
    kodIO: step3.indicatorLimitation?.code || null,
    kodProduktPZS: step3.product?.code || '',
    maKontraindikacie: step3.hasContraindication
      ? step3.hasContraindication === 'Y'
      : null,
    maMV: step3.hasMV === 'Y',
    mnozstvo: Number(step3.quantity),
    navrhujucaNZ: {
      id: step1.ambulance.id,
      kod: step1.ambulance.code,
      odbornost: step1.ambulance.expertise,
    },
    navrhujuciLekar: {
      id: step1.doctor.id,
      kod: step1.doctor.code,
      odbornost: step1.doctor.expertise,
    },
    popisSposobuDavkovania: step3.dosageDescription || '',
    prilohy:
      files?.map((f) => ({
        nazov: f.nazov || '',
        obsah: f.obsah || '',
        typ: f.typ || '',
      })) || null,
    rodneCislo: step2.insureeId,
    zdovodnenieLiecby: step3.reason || '',
  };
  store.dispatch(saveProposal(payload));
};

export const getMessageAssignedToCheckType = (
  checkType: CheckType,
): string | undefined => {
  const texts = strings.proposals.drugs.new.notifications.error;
  switch (checkType) {
    case 'ExistujeProdukt':
      return texts.productDoesNotExist;
    case 'Diagnoza':
      return texts.diagnoseDoesNotExist;
    case 'JePlatny':
      return texts.productNoLongerValid;
    case 'Dlznik':
      return texts.patientIsDebtor;
    case 'DlznikVociCSParNeodklZS':
      return texts.patientIsDebtor;
    case 'MaSekciuPreProdukt': {
      if (
        store
          .getState()
          .drugProposals.new.data.controls.data.some(
            (c) => c.checkType === 'JeCnp' && c.result === Result.OK,
          ) ||
        store
          ?.getState()
          .drugProposals.new.data.controls.data.some(
            (c) =>
              c.checkType === 'MaSekciuPreProdukt' &&
              c.result === Result.NOK &&
              c.errorCode === 'RN065',
          )
      ) {
        return texts.doesNotHaveSectionCnp;
      }
      if (
        store
          .getState()
          .drugProposals.new.data.controls.data.find(
            (c) => c.checkType === 'JeNavrh',
          )?.result === Result.OK
      ) {
        return texts.doesNotHaveSection;
      }
      return texts.doesNotHaveSection;
    }
    case 'JeNutnyNavrh':
      return texts.isProposalRequired;
    case 'MaOdbornost':
      return texts.expertise;
    case 'ProduktVekPoi':
      return texts.insureeAge;
    case 'PohlaviePoi':
      return store.getState()?.drugProposals.new.stepper.step2.poiData?.sex ===
        'M'
        ? texts.onlyForWomen
        : texts.onlyForMen;
    case 'ExistDuplicita':
      return texts.existsDuplicity;
    case 'EsteJePriskoro':
      return texts.tooEarly(
        store
          .getState()
          ?.drugProposals.new.data.controls.data.find(
            (c) => c.checkType === 'EsteJePriskoro',
          )?.additionalInfo?.value1 || '',
      );
    default:
      return undefined;
  }
};

export const getMessageAssignedToCheckTypeModal = (
  checkType: CheckType,
): string | undefined => {
  const texts = strings.proposals.drugs.new.modals.notValid;

  switch (checkType) {
    case 'MaKontraindikaciu':
      return texts.hasContraindication;
    case 'IsVekPoi':
      return texts.ageCriteriaForProductNotFulfilled;
    case 'JePlatny':
      return texts.productNoLongerValid;
    case 'Dlznik':
      return texts.patientIsDebtor;
    case 'DlznikVociCSParNeodklZS':
      return texts.patientIsDebtor;
    case 'DatumPlatnostiOd':
      return texts.incorrectDate;
    case 'MaOdbornost':
    case 'PohlaviePoi':
    case 'ExistDuplicita':
    case 'EsteJePriskoro':
    case 'Mnozstvo':
      return getMessageAssignedToCheckType(checkType);
    default:
      return undefined;
  }
};

export const isCheckWithSameMessage = (data: ProposalCheck[]): boolean => {
  const checksWithSameMessage: CheckType[] = [
    'Dlznik',
    'DlznikVociCSParNeodklZS',
  ];

  const filteredNOKMessages = data.filter(
    (c) =>
      checksWithSameMessage.includes(c.checkType) && c.result === Result.NOK,
  );

  return filteredNOKMessages.length === checksWithSameMessage.length;
};

export const filterNOKChecks = (pc: ProposalCheck) => pc.result === 'NOK';

export const determineStep = (ct: CheckType | null): DrugProposalSteps => {
  switch (ct) {
    case 'PoistnyVztah':
      return 1;
    case 'ExistujeProdukt':
    case 'Diagnoza':
    case 'JePlatny':
    case 'MaKontraindikaciu':
    case 'IsVekPoi':
    case 'Dlznik':
    case 'DlznikVociCSParNeodklZS':
      return 2;
    default:
      return 2;
  }
};

export const determineFieldByCheckType = (ct: CheckType): FieldElementId => {
  switch (ct) {
    case 'Diagnoza':
    case 'Dlznik':
    case 'DlznikVociCSParNeodklZS':
      return FieldElementIdEnum.Diagnose;
    case 'IsVekPoi':
    case 'JePlatny':
    case 'ExistujeProdukt':
      return FieldElementIdEnum.Product;
    case 'MaKontraindikaciu':
      return FieldElementIdEnum.Contraindication;
    default:
      return FieldElementIdEnum.Product;
  }
};

export const scrollToStep = (id: FieldElementId) => {
  const element = document.querySelector(`[id^='${id}']`);
  if (!element) return;

  const headerHeight = 71;
  if (element) {
    // @ts-ignore
    element.style.scrollMargin = `${headerHeight}px`;
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

export const getModalErrorChecks = (data: ProposalCheck[]): ProposalCheck[] =>
  data.filter(
    (pc) => filterNOKChecks(pc) && shouldDisplayInModal(pc.checkType, data),
  );

const shouldDisplayInModal = (
  checkType: CheckType,
  data: ProposalCheck[],
): boolean => {
  if (
    (isCheckWithSameMessage(data) && checkType === 'DlznikVociCSParNeodklZS') ||
    (!isCheckWithSameMessage(data) && checkType === 'Dlznik')
  )
    return false;
  if (checkWithNoMessage.includes(checkType)) return false;

  return data.some((d) => d.checkType === checkType && d.errorWeight === 'E');
};

export const formScroll = (checkTypes: CheckType[]) => {
  switch (true) {
    case checkTypes.includes('JePlatny'):
      scrollToField(FieldElementIdEnum.Product);
      break;
    case checkTypes.includes('Diagnoza'):
      scrollToField(FieldElementIdEnum.Diagnose);
      break;
    case checkTypes.includes('MaKontraindikaciu'):
      scrollToField(FieldElementIdEnum.Contraindication);
      break;
    case checkTypes.includes('MaMV'):
      scrollToField(FieldElementIdEnum.MinisterException);
      break;
    case checkTypes.includes('DatumPlatnostiOd'):
      scrollToField(FieldElementIdEnum.DateFrom);
      break;
    case checkTypes.includes('Mnozstvo'):
      scrollToField(FieldElementIdEnum.Quantity);
      break;
    default:
      break;
  }
};
