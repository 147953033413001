import React from 'react';
import useStyles from '../../../../Proposals.styles';
import { FieldRenderProps } from 'react-final-form-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../rootReducer';
import {
  Notification,
  RadioElement as RE,
} from '../../../../../../types/dds.types';
import {
  addControl,
  resetControls,
  storeStep3,
} from '../../../../../../slices/drugProposals.slice';
import strings from '../../../../../../constants/strings';
import RadioElement from '../../../common/RadioElement/RadioElement';
import { FieldElementIdEnum } from '../../../../../../types/drugProposals.types';
import { scrollToField } from '../../../../../../utils/form.utils';

interface ContraindicationProps {
  field: FieldRenderProps<any, string>;
}

const texts = strings.proposals.drugs.new;

const Contraindication = ({ field }: ContraindicationProps) => {
  const classes = useStyles({ proposalType: 'LN' });
  const dispatch = useDispatch();
  const {
    data: {
      formData: { data },
    },
    stepper: {
      step3: { hasContraindication },
    },
  } = useSelector((state: RootState) => state.drugProposals.new);

  const handleChange = (v: string) => {
    scrollToField(FieldElementIdEnum.Contraindication);
    dispatch(resetControls({ controls: ['MaKontraindikaciu'] }));
    if (v === RE.Yes)
      dispatch(
        addControl({
          control: 'MaKontraindikaciu',
          errorWeight: 'E',
          result: 'NOK',
        }),
      );
    dispatch(storeStep3({ hasContraindication: v }));
  };

  if (!data?.udajParLiekNavrhuCnp?.kontraIndikacie?.length) return null;
  return (
    <div className={classes.drugsWrapper}>
      <RadioElement
        field={field}
        help={
          <ul>
            {data.udajParLiekNavrhuCnp.kontraIndikacie.map((i, key) => (
              <li key={`contraindication--${key}`}>{i}</li>
            ))}
          </ul>
        }
        id={FieldElementIdEnum.Contraindication}
        label={texts.labels.contraindication}
        notification={
          (hasContraindication === RE.Yes && {
            message: texts.notifications.error.contraindications,
            type: Notification.Error,
          }) ||
          undefined
        }
        onChange={handleChange}
        type="Nemá/Má"
        value={field.input.value}
        withNotificationClass={classes.drugsWrapper}
        wrapperClass={classes.radiocheckWrapper}
      />
    </div>
  );
};

export default Contraindication;
