/* eslint-disable */
import { validation } from '../constants/validation';
import strings from '../constants/strings';

export const getPasswordStrengthNumber = (password, debug = 0) => {
  if (!password) return 0;

  const checks = [
    { regex: /^(?=.*[\\.\\_\\-\\@])/, log: 'special character', score: 20 },
    { regex: /^(?=.{6,128})/, log: 'length > 8', score: 20 },
    { regex: /^(?=.*[0-9])/, log: 'contain number', score: 20 },
    { regex: /^(?=.*[a-z])/, log: 'contain small character', score: 20 },
    {
      regex: /^(?=.*[A-Z\u0161\u0111\u010D\u0107\u017E])/,
      log: 'contain big character',
      score: 20,
    },
  ];

  return checks.reduce((sum, check) => {
    if (check.regex.test(password)) {
      if (debug) console.log(check.log);
      return sum + check.score;
    }
    return sum;
  }, 0);
};

export const validationMsg = (code: string, someParam?: string): string => {
  if (code && validation[code])
    return someParam ? validation[code](someParam) : validation[code];
  return strings.defaultError;
};

export const scrollToField = (id: string) => {
  const el = document.getElementById(id);
  if (!el) return;

  el.scrollIntoView({ behavior: 'smooth', block: 'center' });
};
