import { ReactNode, useEffect, useState } from 'react';
import { Dokument } from '../../types/models/Dokument';
import UploadForm from './UploadForm';
import { uploadHandler } from './utils';
import IconUpload from '../CustomIcons/IconUpload';
import { color } from '@dovera/design-system';
import useStyles from './UploadForm.styles';

interface Props {
  allowedExtensions?: (
    | 'doc'
    | 'docx'
    | 'pdf'
    | 'png'
    | 'jpg'
    | 'jpeg'
    | 'zip'
    | string
  )[];
  forbiddenExtensions?: string[];
  id?: string;
  labelText?: ReactNode;
  maxFileSize?: number;
  onChange: (files: Dokument[] | null) => void;
  onUploadError: (isErr: boolean) => void;
  reset?: boolean;
  validationError?: string;
}

const Upload = ({
  allowedExtensions,
  forbiddenExtensions,
  id,
  labelText,
  maxFileSize,
  onChange,
  onUploadError,
  reset,
  validationError,
}: Props) => {
  const classes = useStyles();
  const [files, setFiles] = useState<File[] | null>(null);
  useEffect(() => {
    if (files) {
      uploadHandler(files).then((files) => onChange(files));
    }
    // eslint-disable-next-line
  }, [files]);
  useEffect(() => {
    setFiles(null);
  }, [reset]);
  return (
    <UploadForm
      allowedExtensions={
        allowedExtensions || ['pdf', 'png', 'jpg', 'jpeg', 'zip']
      }
      files={
        files?.map((f) => ({
          name: f.name,
          size: f.size,
          type: f.type,
        })) || null
      }
      forbiddenExtensions={new Set(forbiddenExtensions || ['exe'])}
      formError={validationError}
      id={id}
      inspectMimeTypes
      isUploading={false}
      maxFileSize={maxFileSize || 20}
      multiple
      onResetForm={(f, index, isErr) => {
        setFiles(
          files?.filter(
            (o, key) =>
              !(o.name === f.name && o.size === f.size && index === key),
          ) || null,
        );
        onUploadError(!!isErr);
      }}
      onUpload={(f, isErr) => {
        setFiles([...(files || []), ...f]);
        onUploadError(!!isErr);
      }}
      uploadError={(isErr) => onUploadError(isErr)}
      uploadFormLabelText={
        labelText || (
          <div className={classes.uploadLabel}>
            <IconUpload color={color('black')} id="icon--upload-ln" />
            Prílohy môžete{' '}
            <span className="clickable text-color-primary underline">
              nahrať
            </span>{' '}
            alebo sem presunúť.
          </div>
        )
      }
    />
  );
};

export default Upload;
