import { CheckProposalDataResponse } from '../../types/drugProposals.types';
import { CheckProposalDataResponseSK } from '../apiTypes/drugProposals.types';

export const convertCheckProposal = (
  data: CheckProposalDataResponseSK,
): CheckProposalDataResponse => ({
  data: data.udaje.map((udaj) => ({
    additionalInfo: {
      value1: udaj.doplnujuceInfo?.hodnota1 || '',
      value2: udaj.doplnujuceInfo?.hodnota2 || '',
      value3: udaj.doplnujuceInfo?.hodnota3 || '',
      value4: udaj.doplnujuceInfo?.hodnota4 || '',
      value5: udaj.doplnujuceInfo?.hodnota5 || '',
      value6: udaj.doplnujuceInfo?.hodnota6 || '',
    },
    checkResult: udaj.vysledokKontroly,
    checkType: udaj.kontrolaTyp,
    errorCode: udaj.kodChyby,
    errorDescription: udaj.popisChyby,
    errorDetail: udaj.detailChyby,
    errorWeightCode: udaj.kodVahaChyby,
  })),
  formId: data.formularId,
});
