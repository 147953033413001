import { Choice } from 'choices.js';
import Autocomplete from '../../../../../../components/Autocomplete';
import IconSearch from '../../../../../../components/CustomIcons/IconSearch';
import strings from '../../../../../../constants/strings';
import useStyles from '../../../../Proposals.styles';
import {
  checkProposalData,
  getDiagnosesList,
} from '../../../../../../api/drugProposals';
import { useAppDispatch } from '../../../../../../hooks/useStore';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../rootReducer';
import { storeStep3 } from '../../../../../../slices/drugProposals.slice';
import CheckNotifications from '../../../common/CheckNotifications/CheckNotifications';
import { CheckType } from '../../../../../../types/proposals.types';
import { FieldElementIdEnum } from '../../../../../../types/drugProposals.types';
import { scrollToField } from '../../../../../../utils/form.utils';

interface Props {
  field: any;
  onReset: () => void;
}

const texts = strings.proposals.drugs.new;
const checkTypes: CheckType[] = [
  'Diagnoza',
  'Dlznik',
  'DlznikVociCSParNeodklZS',
];
const checkNotifications: CheckType[] = [...checkTypes];

const Diagnose = ({ field, onReset }: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles({ proposalType: 'LN' });
  const lastRequestInfo = useRef<{
    controller: AbortController | null;
    query: string | null;
  }>({ controller: null, query: null });
  const { diagnose } = useSelector(
    (state: RootState) => state.drugProposals.new.stepper.step3,
  );

  useEffect(() => {
    if (!diagnose) return;

    dispatch(
      checkProposalData({
        checkTypes,
        diagnosisCode: diagnose.kod,
      }),
    );
  }, [diagnose, dispatch]);

  return (
    <div className={classes.drugsWrapper}>
      <Autocomplete
        {...field.input}
        addonsInside
        autofillSingleResult
        delay={200}
        error={
          field.meta.touched &&
          !field.meta.active &&
          field.meta.error &&
          field.meta.error
        }
        id={FieldElementIdEnum.Diagnose}
        isRequired
        label={texts.labels.dg}
        minLength={2}
        noMarginBottom
        notFilterOptions
        onClick={() => scrollToField(FieldElementIdEnum.Product)}
        // eslint-disable-next-line
        onChange={(value: string) => {
          field.input.onChange(value);
        }}
        onSelect={(option: Choice | any) => {
          if (!option) return;
          if (option) {
            field.input.onChange(option.label);
            onReset();
            dispatch(
              storeStep3({
                diagnose: {
                  kod: option.value,
                  nazov: option.label.split(`${option.value} - `)?.[1],
                },
              }),
            );
          }
        }}
        rightAddons={
          <IconSearch className="clickable" id="proposals--diagnose--search" />
        }
        source={(query) => {
          if (
            query.includes(lastRequestInfo?.current?.query || '') &&
            lastRequestInfo.current.controller
          ) {
            // Abort the last request
            lastRequestInfo.current.controller.abort();
          }
          const controller = new AbortController();
          const { signal } = controller;
          lastRequestInfo.current = { controller, query };

          return getDiagnosesList(
            {
              hladanaDiagnoza: query,
            },
            signal,
          );
        }}
      />
      <CheckNotifications checkTypes={checkNotifications} />
    </div>
  );
};

export default Diagnose;
