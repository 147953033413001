const convertor = {
  period: 'obdobie',
  periods: 'obdobia',
  action: 'akcia',
  isActiveAction: 'jeAktivnaAkcia',
  codePeriodType: 'kodTypObdobie',
  periodTitle: 'nadpisObdobie',
  periodDescription: 'popisObdobie',
  banners: 'banery',
  image: 'obrazok',
  type: 'typ',
  cycleId: 'idPZSOdbCyklus',
  periodCalculationTo: 'obdobieVypocetDo',
  periodCalculationFrom: 'obdobieVypocetOd',
  priceContent: 'obsahCeny',
  value: 'hodnota',
  title: 'nadpis',
  name: 'nazov',
  tips: 'tipy',
  chartContent: 'obsahGraf',
  chartData: 'datagraf',
  groupType: 'typSkupina',
  contentTitleParameter: 'obsahNadpisParametra',
  subTitle: 'podpnadpis',
  description: 'popis',
  parameterGroups: 'skupinyParametrov',
  parameterCards: 'kartyParametrov',
  dataForExchange: 'dataVyberuParametrov',
  groupId: 'idSkupiny',
  exchangeGroupId: 'idSkupinyVymeny',
  exchangeGroup: 'skupinaVymena',
  selectionType: 'typVyberu',
  exchangeType: 'typVymena',
  selected: 'vybrane',
  parameterCode: 'kodParameter',
  parameter: 'parameter',
  parameters: 'parametre',
  state: 'stav',
  tags: 'tagy',
  quantityToSelect: 'mnozstvoNaVyber',
  filling: 'plnenie',
  group: 'skupina',
  values: 'poleHodnot',
  valueName: 'nazovHodnoty',
  periodTo: 'obdobieDo',
  periodFrom: 'obdobieOd',
  expertiseCode: 'kodOdbAmb',
  healthCareCode: 'kodTypZs',
  idCycleRow: 'idRiadokCyklu',
  idEvaluatedHCP: 'idHodnotenyPzs',
  idUser: 'idPouzivatel',
  idVZ: 'idVZ',
  order: 'poradie',
  amountMin: 'mnozstvoMin',
  amountMax: 'mnozstvoMax',
  resultParamId: 'idVysledokParam',
  required: 'povinny',
  contentCardParameter: 'obsahKartaParam',
  periodId: 'idObdobie',
  codeParam: 'kodParametra',
  priceType: 'typCeny',
  price: 'cena',
  selectedParameters: 'parametreVymeny',
  parameterId: 'idParametra',
  choosed: 'vybrany',
  maxValue: 'hranicnaHodnotaMax',
  minValue: 'hranicnaHodnotaMin',
  positionHCP: 'poziciaPzs',
  averageValue: 'priemernaHodnota',
  weight: 'vaha',
  closed: 'uzavrete',
  dateValidityFrom: 'datumPlatnostiOd',
  formId: 'formularId',
  doctorId: 'idLekarZdravPrac',
  isCNP: 'jeCNP',
  diagnosisCode: 'kodDiagnoza',
  productCode: 'kodProduktPZS',
  checkTypes: 'kontrolaTypy',
  checkType: 'kontrolaTyp',
  checkResult: 'vysledokKontroly',
  proposingExpertise: 'navrhujucaNZ',
  proposingDoctor: 'navrhujuciLekar',
  insureeId: 'rodneCislo',
  errorCode: 'kodChyby',
  errorWeightCode: 'kodVahaChyby',
  errorDescription: 'popisChyby',
  errorDetail: 'detailChyby',
  additionalInfo: 'doplnujuceInfo',
  hodnota1: 'value1',
  hodnota2: 'value2',
  hodnota3: 'value3',
  hodnota4: 'value4',
  hodnota5: 'value5',
  hodnota6: 'value6',
  expertise: 'odbornost',
  code: 'kod',
  quantity: 'mnozstvo',
};

export default convertor;
