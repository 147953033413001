import { VALIDATION } from '../types/validation.types';
import { systemConstants } from './systemConstants';

export const validation = {
  [VALIDATION.InProgress]:
    'Predchádzajúca zmena údajov ešte nebola spracovaná. Môže to trvať max. 5 minút.',
  [VALIDATION.LoginEmpty]: 'Zadajte prihlasovacie meno.',
  [VALIDATION.LoginNewEmpty]: 'Zadajte <b>nové</b> prihlasovacie meno.',
  [VALIDATION.LoginExists]: 'Toto meno je obsadené. Zvoľte si, prosím, iné.',
  [VALIDATION.LoginLength]: 'Meno musí mať min. 2 a max. 100 znakov.',
  [VALIDATION.LoginIncorrect]: 'Zadané meno sa nezhoduje s Vaším aktuálnym.',
  [VALIDATION.LoginRegex]:
    'Zadali ste nepodporovaný znak. Okrem písmen a číslic môžete v mene použiť @ - _ .',
  [VALIDATION.PasswordEmpty]: 'Zadajte aktuálne prihlasovacie heslo.',
  [VALIDATION.PasswordNewEmpty]: 'Zadajte nové prihlasovacie heslo.',
  [VALIDATION.PasswordRepeatEmpty]:
    'Zopakujte <b>nové</b> prihlasovacie heslo.',
  [VALIDATION.PasswordNewSameAsOld]:
    'Heslo sa zhoduje s aktuálnym. Zadajte iné heslo.',
  [VALIDATION.PasswordRegex]:
    'Heslo musí mať min. 6 znakov, aspoň <b>1 veľké a malé písmeno a číslicu.</b><br />Môže mať iba tieto znaky @$_-€!%*/+,#?.',
  [VALIDATION.WrongPassword]: 'Zadané heslo sa nezhoduje s Vaším aktuálnym.',
  [VALIDATION.PasswordRepeatNotSame]: 'Heslá sa nezhodujú. Zadajte ich znovu.',
  [VALIDATION.MaxAttemptsPassword]:
    'Prekročili ste 5 pokusov na zmenu. Zatiaľ sa prihlasujte <b>aktuálnym heslom</b>, nové si môžete vytvoriť o 24 hodín.',
  [VALIDATION.MaxAttemptsLogin]:
    'Prekročili ste 5 pokusov na zmenu. Zatiaľ sa prihlasujte <b>aktuálnym menom</b>, nové si môžete vytvoriť o 24 hodín.',
  [VALIDATION.MaxAttempts]: 'Prekročili ste počet pokusov.',
  [VALIDATION.RequiredEmpty]: 'Povinné pole.',
  [VALIDATION.RequiredFirstName]: 'Zadajte krstné meno.',
  [VALIDATION.RequiredLastName]: 'Zadajte priezvisko.',
  [VALIDATION.RequiredName]: 'Zadajte meno.',
  [VALIDATION.RequiredVs]: 'Zadajte variabilný symbol.',
  [VALIDATION.LettersOnly]: 'Pole môže obsahovať iba veľké a malé písmená.',
  [VALIDATION.NumbersOnly]:
    'Pole môže obsahovať iba číslice s maximálnou dĺžkou 10 znakov.',
  [VALIDATION.EmailIncorrect]: 'Zadajte e-mail v správnom formáte.',
  [VALIDATION.RepeatEmailIncorrect]: 'E-maily sa nezhodujú. Zadanie zopakujte.',
  [VALIDATION.PhoneIncorrect]: 'Zadajte číslo na pevnú linku.',
  [VALIDATION.MobileIncorrect]: 'Zadajte číslo vo formáte 09xx xxx xxx.',
  [VALIDATION.AuthDoctorStatDifferentPerson]:
    'Meno lekára je iné ako meno štatutára. V predchádzajúcom kroku vyberte buď lekára alebo štatutára, nie obe.',
  [VALIDATION.AuthChooseOneOption]: 'Vyberte jednu z možností.',
  [VALIDATION.AuthReservedAccountStat]:
    'Pre tento účet je priradený iný štatutár.<br />Vyberte iného štatutára alebo si v ďalšom kroku vytvorte nový personalizovaný účet.',
  [VALIDATION.AuthIncorrectDoctorCode]:
    'Neevidujeme Váš úväzok u tohto poskytovateľa.<br />Požiadajte vlastníka Elektronickej pobočky o nahlásenie.',
  [VALIDATION.AuthAlreadyPersonalizedAccount]:
    'Účet s týmto kódom už bol vytvorený.',
  [VALIDATION.AuthAlreadyPersonalizedDifferentAccount]:
    'Lekár s týmto kódom je už priradený k inému účtu.',
  [VALIDATION.AuthDefaultError]:
    'Skontrolujte kód lekára. Ak chyba pretrváva, napíšte nám na <a class="text-black" href="mailto:poskytovatel@dovera.sk"><b>poskytovatel@dovera.sk</b></a>.',
  [VALIDATION.AuthInputNotSameSTAT]: 'Štatutár sa nezhoduje s kódom lekára',
  [VALIDATION.AuthInputNotSameDoctor]: 'Zadali ste kód iného lekára',
  [VALIDATION.AuthStatutoryError]:
    'Napíšte nám na <a class="text-black" href="mailto:poskytovatel@dovera.sk"><b>poskytovatel@dovera.sk</b></a>. Zistíme, kde je problém.',
  [VALIDATION.AuthStatutoryDifferentAccount]:
    'Účet štatutára už bol vytvorený a nie je možné vytvoriť ďalší.<br />Ak chcete pokračovať, v predchádzajúcom kroku <b>vyberte inú pozíciu</b> ako štatutár.',
  [VALIDATION.InsureeIdWrongLength]:
    'Skontrolujte, či má rodné číslo aspoň 9 číslic.',
  [VALIDATION.InsureeIdWrongFormat]: 'Zadajte iba číslice.',
  [VALIDATION.DateEmptyDefault]: 'Vyberte dátum z kalendára alebo ho vpíšte.',
  [VALIDATION.DateIncorrect]: 'Nesprávny formát dátumu.',
  [VALIDATION.ProposalReservationCodeNotValid]: 'Tento kód nie je platný.',
  [VALIDATION.NotDZPInsuree]:
    'Skontrolujte, či ste zadali správne rodné číslo.',
  [VALIDATION.AuthErrorStatNotFound]: 'Nenašli sme žiadnych štatutárov.',
  [VALIDATION.ProposalEscortReason]: 'Vyberte aspoň 1 dôvod sprievodu.',
  [VALIDATION.SqlInjection]: (str: string) =>
    `Váš text obsahuje tento problematický súbor znakov: <b>${str}</b>. Upravte text, prosím.`,
  [VALIDATION.ForbiddenFileType]: (allowedTypes?: string[]) =>
    allowedTypes
      ? `<b>Súbor je v nesprávnom formáte.</b> Prosím, nahrajte ho ako ${allowedTypes.join(
          getStringByLength(allowedTypes.length),
        )}.`
      : 'Nepovolený typ súboru.',
  // Zuctovanie
  [VALIDATION.ChybnaKombinaciaVstupnychUdajov]:
    'Chybná kombinácia vstupných údajov.',
  [VALIDATION.ChybaPriZiskavaniDokumentuDms]:
    'Nastala chyba pri získavaní dokumentu z cieľového systému DMS.',
  [VALIDATION.ChybaPriZiskavaniDokumentuTxs]:
    'Nastala chyba pri získavaní dokumentu z cieľového systému TXS.',
  [VALIDATION.NepodporovanyTypDavky]:
    'Nahraný súbor dávky má nepodporovaný typ dávky.',
  [VALIDATION.ChybnaSyntax]: 'Nahraná dávka obsahuje syntaktické chyby.',
  [VALIDATION.OpravnaDavkakNeexistujucejDavke]:
    'Dávku charakteru <b>Opravná</b> nie je možné odoslať, v systéme chýba dávka charakteru <b>Nová</b>, resp. <b>Aditívna</b> za vykazované obdobie.',
  [VALIDATION.DuplicitnaDavka]:
    'Nahrali ste dávku, ktorú už v systéme evidujeme.',
  [VALIDATION.ChybnaSemantika]: 'Nahraná dávka obsahuje sémantické chyby.',
  [VALIDATION.NenajdeneDavkyPodlaIdSkupiny]:
    'Na základe vstupných údajov neboli nájdené žiadne dávky.',
  [VALIDATION.ChybaPriZiskavaniProtokoluTxs]:
    'Nastala chyba pri získavaní protokolu z cieľového systému TXS.',
  [VALIDATION.ChybaPriZiskavaniFakturApm]:
    'Nastala chyba pri získavaní faktúr z cieľového systému APM.',
  [VALIDATION.DavkaNepatriPoskytovatelovi]:
    'Nahraná dávka nepatrí prihlásenému poskytovateľovi ZS.',
  [VALIDATION.NenajdeneDavky]: 'Neboli nájdené žiadne dávky.',
  [VALIDATION.NenajdeneZmluvy]: 'Neboli nájdené zmluvy.',
  [VALIDATION.ChybaPriPotvrdzovaniFakturyApm]:
    'Nastala chyba pri potvrdzovaní faktúry.',
  [VALIDATION.MaxVelkostDavky]: 'Prekročili ste max. veľkosť dávky.',
  [VALIDATION.PovoleneTypyPriloh]: (type) =>
    `Prikladáte nepovolený typ súboru. Povolené formáty súborov sú DOC, DOCX, XLS, XLSX, RTF, PDF, JPG, GIF, TIFF. ${
      type ? `Typ súboru na vstupe: ${type}` : ''
    }.`,
  [VALIDATION.MaximalnaVelkostPrilohSpolu]: (size: number) =>
    `Prílohy nesmú prekročiť <b>${size} MB</b>. Skúste ich poslať ako .zip.`,
  [VALIDATION.ChybneDavky]: 'Nahrali ste chybnú dávku.',
  [VALIDATION.NepripojenaFaktura]:
    'Pripojte prosím faktúru. Bez pripojenej faktúry nie je možné pokračovať.',
  [VALIDATION.MaximalnaSumaFaktury]:
    'Pre istotu si skontrolujte, či je suma správna.',
  [VALIDATION.NepovolenyVariabilnySymbol]:
    'Faktúra s týmto variabilným symbolom už existuje.<br />Prosím, uveďte iný.',
  [VALIDATION.NepripojenyDokumentOdovodnenia]:
    'Prosím, nahrajte dokument odôvodnenia.',
  [VALIDATION.ChybneRozdelenie]: 'Nastala chyba v rozdelení dávok na faktúry.',
  [VALIDATION.ChybneObdobie]: 'Chybné obdobie na vstupe.',
  [VALIDATION.ChybaPriUkladaniDokumentovFaktury]:
    'Nastala chyba pri ukladaní dokumentov faktúry.',
  [VALIDATION.ChybaPriParovaniFaktury]: 'Nastala chyba pri párovaní faktúry.',
  [VALIDATION.NesmieBytPripojenaFaktura]: 'Faktúra nesmie byť pripojená.',
  [VALIDATION.NiesuVyplneneUdajeFaktury]: 'Nie sú vyplnené údaje faktúry.',
  [VALIDATION.NenajdenyDokumentFaktury]: 'Dokument faktúry nebol nájdený.',
  [VALIDATION.ChybaPriParovaniDavky]: 'Nastala chyba pri párovaní dávky.',
  [VALIDATION.MaximalnaVelkostPrilohy]: (
    size = systemConstants.MAX_UPLOAD_SIZE,
  ) =>
    `Priložený súbor je príliš veľký, povolená veľkosť pre jednotlivý súbor je <b>${size} MB</b>.`,
  [VALIDATION.NenajdeneDavkyCL]: 'Dávky podľa zadaného id neboli nájdené.',
  [VALIDATION.ChybneIdOdoslaniaCL]:
    'Prepáčte, odoslanie dávok zlyhalo. Skúste neskôr, prosím.',
  [VALIDATION.ChybnyTypDavkyCL]:
    'Nesprávny typ dávky alebo iný parameter hlavičky.',
  [VALIDATION.ChybnaPrislusnostDavkyCL]:
    'IČO v hlavičke dávky je iné ako IČO prihláseného používateľa.',
  [VALIDATION.ChybnaChronologiaCL]:
    'Dátum odoslania dávky alebo číslo dávky nezodpovedá očakávanému poradiu.',
  [VALIDATION.DuplicitaDavokCL]: 'Odosielate niektoré z dávok duplicitne.',
  [VALIDATION.FutureBatchCL]: 'Dátum dávky v hlavičke je z budúcnosti.',
  [VALIDATION.NeplatneCisloUtvaruCL]:
    'Číslo útvaru v hlavičke dávky nie je k dátumu vytvorenia dávky platné.',
  [VALIDATION.NespravnyCharakterCL]:
    'Nesprávny charakter dávky v hlavičke dávky.',
  [VALIDATION.NespravnyTypCL]: 'Nesprávny typ dávky v hlavičke dávky.',
  [VALIDATION.NespravnyFormatIcoCL]: 'Nesprávny formát IČO v hlavičke dávky.',
  [VALIDATION.NespravnyFormatDatumCL]:
    'Nesprávny formát dátumu odoslania dávky.',
  [VALIDATION.NespravnyFormatPoradieCL]:
    'Nesprávny formát poradového čísla dávky.',
  [VALIDATION.NespravnyFormatDokladyCL]:
    'Nesprávny formát počtu dokladov v hlavičke dávky.',
  [VALIDATION.NespravnyKodZPCL]:
    'Nesprávny kód zdravotnej poisťovne v hlavičke dávky.',
  [VALIDATION.NespravnyPocetPipeCL]:
    'Nesprávny počet oddeľovacích znakov v hlavičke dávky.',
  [VALIDATION.NevalidnySuborCL]:
    'Na vstupe nebol priložený validný súbor dávky.',
  [VALIDATION.DavkuCLNiejeMozneVytvoritPred2024]:
    'Dávku nie je možné vytvoriť pred 1.1.2024.',
  [VALIDATION.ProductEmpty]: 'Začnite písať kód alebo názov produktu',
  [VALIDATION.DiagnoseEmpty]: 'Začnite písať diagnózu',
  [VALIDATION.GreaterThanZero]:
    'Hodnota musí byť väčšia alebo rovná hodnote 1.',
  [VALIDATION.ProductQuantity]: (count: number) =>
    `Pre tento produkt môžete zadať maximálne ${count} kus${count > 1 && count < 5 ? 'y' : count !== 1 ? 'ov' : ''}.`,
  [VALIDATION.MaxIntValue]: `Hodnota musí byť menšia alebo rovná hodnote ${systemConstants.MAX_INPUT_INT}.`,
  [VALIDATION.OnlyInteger]: 'Hodnota musí byť celé číslo.',
};

const getStringByLength = (length: number) => (length === 2 ? ' alebo ' : ', ');
